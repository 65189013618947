<template>
  <div class="join-container minSize" :class="bgClassType === 1 ? 'bg-default' : 'bg-omnitest' " >
    <div id="videoconference_page">
      <div class="premeeting-screen" id="lobby-screen">
        <div id="preview" v-show="isShowVideo">
          <video autoplay class="flipVideoX media-reset-flag" id="joinVideo" playsinline></video>
        </div>
        <div class="content">
          <div class="box" :class="{'gray': isShow}">
            <div class="title">
              <p :class="[isShow ? 'active' : '']">{{titleInfo}}</p>
            </div>
            <div class='time_style'>{{timeinfo}}</div>
            <div class='line_style'></div>
            <div class="prejoin-input-area">
              <div style="margin-top: 32px">
                <el-checkbox id="join_microphone_checkbox_headless" :class="video ? 'labelColor': 'no_video'"
                  v-model="myCheckbox">{{$t('login.openMicrophone')}}</el-checkbox>
              </div>
              <div style="margin-top: 16px">
                <el-checkbox id="join_speaker_checkbox_headless" :class="video ? 'labelColor': 'no_video'"
                  v-model="loudspeaker">{{$t('login.openSpeaker')}}</el-checkbox>
              </div>
              <div style="margin-top: 16px">
                <el-checkbox id="join_camera_checkbox_headless" :class="video ? 'labelColor': 'no_video'"
                  v-model="myCamera" @change="changeDevice">{{$t('login.openCamera')}}</el-checkbox>
              </div>
              <div class="prejoin-button">
                <el-button id="join_handle_btn_headless" type="primary" size="small" @click="handleJoin">
                  {{$t('login.login')}}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 输入密码 -->
    <el-dialog custom-class="commonElDialog" width="380px" :show-close="false" :close-on-click-modal="false"
      :close-on-press-escape="false" :visible="passwordDialog" @close="passwordDialogCloseHandle">
      <div class="dialog-title" style="marginBottom: 15px;">
        <div class="text">
          {{ $t('login.pleasePws') }}
        </div>
      </div>

      <el-form :model="passwordForm" :rules="passwordRules" ref="passwordForm">
        <el-form-item prop="pwd">
          <el-input :placeholder="$t('login.meetNo')" clearable v-model="passwordForm.pwd"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordDialog = false">
          {{ $t('login.cancel') }}
        </el-button>

        <el-button type="primary" @click="passwordSubmit">
          {{ $t('login.sure') }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 是否可以预约会议和个人会议提前进入 -->
    <el-dialog custom-class="advanceElDialog" :width="$i18n.locale === 'en-US' ? '570px' : '420px'"
      :visible.sync="advanceDialog" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      @close="advanceDialogCloseHandle">
      <!-- <div class="axiosTitle">{{ advanceErrorData.content }}</div> bug 35594 要求修改 -->
      <div class="axiosTitle">请等待主持人进入当前会议</div>

      <div class="axiosItem" v-if="advanceErrorData.beginTime && advanceErrorData.type === 1">
        <div class="axiosItemTitle">{{ $t('login.startTime') }}</div>
        <div class="axiosItemValue">{{ advanceErrorData.beginTime }}</div>
      </div>

      <!-- <div class="axiosItem">
        <div class="axiosItemValue mutRowsTitle">{{ advanceErrorData.title }}</div>  
      </div>
      <div class="axiosItem">
       <div class="axiosItemValue2">{{$t('login.ifMain')}}</div>
      </div> bug 35594 要求隐藏 -->

      <span slot="footer" class="dialog-footer dialog-marginTop" style='display:inline-block'>
        <!-- <el-button @click="goLoginPage">{{$t('home.signIn')}}</el-button>  bug 35594 要求隐藏-->
        <el-button type="primary" @click="advanceDialog = false">{{$t('login.leaveMeet')}}</el-button>
      </span>
    </el-dialog>

    <!-- 主持人正在开启另外的会议 -->
    <el-dialog custom-class="commonElDialog" width="380px" :show-close="false" :close-on-click-modal="false"
      :close-on-press-escape="false" :visible="waitHostDialog" @close="waitHostDialogCloseHandle">
      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">
          {{ waitHostErrorData.tips }}
        </div>
      </div>

      <div class="dialog-content">
        <div class="content-item">
          {{ $t('login.meetNumber') + waitHostErrorData.conferenceNo }}
        </div>
        <div class="content-item">
          {{ $t('login.meetTheme') + waitHostErrorData.title }}
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="waitHostDialog = false">
          {{ $t('login.leaveMeet')}}
        </el-button>
      </span>
    </el-dialog>

    <!-- 结束会议弹窗 -->
    <el-dialog custom-class="commonElDialog" width="380px" :visible.sync="endMeetElDialog">

      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">{{ endMeetElDialogTitle }}</div>
      </div>

      <div class="dialog-content">
        <div class="content-item">
          {{ $t('login.meetNumber') + forceEndMeetInfo.conferenceNo }}
        </div>
        <div class="content-item">
          {{ $t('login.meetTheme') + forceEndMeetInfo.title }}
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="forceEndMeetHandle">{{$t('login.end')}}</el-button>
        <el-button @click="endMeetElDialog = false">{{$t('login.noEnd')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import throttle from 'lodash.throttle'
  import joinErrorMixins from '@/mixins/joinError.mixins'
  import {
    strToBoolean
  } from '@/utils/str'
  import {
    THROTLE_TIME
  } from "@/constant/index";
  import {
    getUrlDate,
    getDate
  } from "@/utils/tool"
  import {
    getLocalConferenceNo,
    setLocalConferenceNo,
    getDisplayName,
    setDisplayName,
    setMeetInfo,
    getMopanLinks,
    setMopanLinks,
    setMeetToken,
    getMeetToken,
    setReJoinUid
  } from '@/utils/auth'
  import {
    getLocalDeviceInfo
  } from "@/utils/device"
  import {
    joinConference,
    landingToken,
    landingConfirm
  } from '@/api/meet'

  export default {
    mixins: [joinErrorMixins],
    components: {},
    data() {
      return {
        video: "",
        myCheckbox: true,
        loudspeaker: true,
        myCamera: false,
        isShow: false,
        isShowVideo: false,
        isShowBackLink: true,
        conferenceNo: '',
        userName: '',
        titleInfo: '',
        timeinfo: '',
        bgClassType:1,
      };
    },
    created() {
      this.getHref()
    },
    mounted() {
      let that = this;
      let conferenceNo = that.$route.query.conferenceNo;
      if (conferenceNo) { // 邀请函，url上附加conferenceNo
        that.conferenceNo = conferenceNo;
      } else { // 查看本地存储
        that.conferenceNo = getLocalConferenceNo() || ''
      }
      // that.userName = getDisplayName() || this.$store.state.user.realName
      //从上次操作记忆中加载勾选状态
      let isUseHuaTong = that.$loadLocal("isUseHuaTong")
      that.myCheckbox = strToBoolean(isUseHuaTong, true)

      let isUseShiPin = that.$loadLocal("isUseShiPin")
      that.myCamera = strToBoolean(isUseShiPin, false)
      that.handleDevice()

      let isUseYangShengQi = that.$loadLocal("isUseYangShengQi")
      that.loudspeaker = strToBoolean(isUseYangShengQi, true)
    },
    beforeDestroy() {
      this.cancalCloseVideo()
    },
    beforeRouteLeave(to, form, next) {
      this.cancalCloseVideo()
      setTimeout(() => {
        next()
      }, 1000);
    },
    methods: {
      async getHref() {
        let url = window.location.href
        // let url = 'https://mopan-meet.test.100url.cn/join?token=yBiy4uyjQ2A_9fb3785c-9ac7-492c-bdc4-74396ca65450'

        let paramsArr = getUrlDate(url)
        if (paramsArr.token) {
          setMeetToken(paramsArr.token)
        }
        if(paramsArr && paramsArr.domain && paramsArr.domain.match(/omnitest|ryzd/)){
          this.bgClassType = 2
        }
        try {
          const resData = await landingToken({
            token: getMeetToken()
          })
          if (resData.success) {
            //token有效时，返回的redicturl是当前工作台的邀请链接，在会中展示
            this.titleInfo = resData.info.topic
            let startTime = new Date(resData.info.planStartTime)
            let endTime = new Date(resData.info.planEndTime)
            let ymd = getDate(startTime)
            let _endYmd = getDate(endTime)
            this.timeinfo = `${ymd} ${startTime.toTimeString().substr(0, 5)}~${getDate(endTime)!=getDate(startTime) ? _endYmd + ' ' : ''}${ endTime.toTimeString().substr(0,5)}`
          } else {
            setMopanLinks(resData.redirectUrl)
            this.$confirm('您已离开会议', '提示', {
              confirmButtonText: '确定',
              showCancelButton: false,
              closeOnClickModal: false, //是否可通过点击遮罩关闭
              closeOnPressEscape: false, //是否可通过按下 ESC 键关闭 
              showClose: false, //是否显示右上角关闭按钮
              center: true,
            }).then(() => {
              window.location.href = getMopanLinks();
            })

          }
        } catch (error) {
          this.joinErrorHandle(error)
        }
      },
      // 监听打开音频和视频
      changeDevice() {
        if (this.myCamera && !this.video) {
          this.isShowBackLink = false
        }
        this.handleDevice()
      },
      handleDevice: throttle(async function () {
        if (!this.myCamera) {
          this.cancalCloseVideo();
        }
        if (this.myCamera && !this.video) {
          this.handleCamera();
        }
      }, THROTLE_TIME),
      passwordDialogCloseHandle() {
        this.passwordForm.pwd = ''
      },
      // 打开摄像头
      handleCamera() {
        this.video = '';
        let video = document.getElementById("joinVideo");
        let deviceId = this.getCurrentDeviceId()
        let constraints = {
          video: this.myCamera ? {
            width: 1280,
            height: 720,
            deviceId: deviceId
          } : false,
        };
        const that = this
        let promise = navigator.mediaDevices.getUserMedia(constraints);
        promise
          .then((MediaStream) => {
            that.isShowVideo = true
            that.video = MediaStream.getTracks();
            video.srcObject = MediaStream;
            video.play();
            that.isShowBackLink = true

            setTimeout(function () {
              that.isShow = true
            }, 400)
          })
          .catch(function (PermissionDeniedError) {
            console.log(PermissionDeniedError);
            that.isShowBackLink = true
          });
      },
      getCurrentDeviceId() {
        const localDeviceData = getLocalDeviceInfo()
        const camDeviceList = this.$store.state.device.cameraDeviceList

        let queryDeviceId = localDeviceData.videoDeviceId
        if (!queryDeviceId && camDeviceList.length > 0) {
          queryDeviceId = camDeviceList[0].deviceId
        }
        return queryDeviceId
      },
      // 关闭麦克风和摄像头
      cancalCloseVideo() {
        if (this.video) {
          this.video.forEach((item) => {
            item.stop();
          });

          //释放资源
          let videoElement = document.getElementById("joinVideo");
          if (videoElement) {
            videoElement.src = ""
            videoElement.srcObject = null
          }

          this.isShow = false;
          this.isShowVideo = false;
          this.video = "";
        }
      },

      // 加入会议
      handleJoin: throttle(async function () {
        try {
          const resData = await landingConfirm({
            token: getMeetToken()
          })
          if (resData.success) {
            this.goMeet(resData);
          } else {
            if(resData.error.includes('当前在线人数')){
              this.$confirm(resData.error, {
              confirmButtonText: '确定',
              showCancelButton: false,
              closeOnClickModal: false, //是否可通过点击遮罩关闭
              closeOnPressEscape: false, //是否可通过按下 ESC 键关闭 
              showClose: false, //是否显示右上角关闭按钮
              center: true,
            })}else{
              window.location.href = resData.redirectUrl
            }
          }
        } catch (error) {
          this.joinErrorHandle(error)
        }

      }, THROTLE_TIME, {
        leading: true,
        trailing: false
      }),
      async goMeet(resData) {
        let data = {
          conferenceNo: resData.info.code,
          userName: resData.info.username,
          userId: resData.info.userId,
          appId: resData.info.appIdList,
        }
        if (resData.info.requirePassword) {
          if (resData.info.password) {
            data.password = resData.info.password
          } else {
            data.password = this.passwordForm.pwd
          }
        }
        console.error(data,'---加入会议---')
        try {
          const meetData = await joinConference(data)
          const {
            roomid,
            conference,
            userid,
          } = meetData
          this.$saveLocal("isUseHuaTong", this.myCheckbox);
          this.$saveLocal("isUseYangShengQi", this.loudspeaker);
          this.$saveLocal("isUseShiPin", this.myCamera);

          //设置会场中重新入会需要用到的userid
          setReJoinUid(userid)
          // setDisplayName(resData.info.username)
          setMopanLinks(resData.redirectUrl)
          // 入会失败的状态清理
          this.clearErrorState()
          // 存储token信息
          setMeetInfo(
            meetData["X-Conference-Token"],
            meetData["X-Channel-Token"]
          )
          let url = window.location.href
          let paramsArr = getUrlDate(url)
          let _queryData = {
            roomID: roomid, // 房间id
            conferenceNo: conference.conferenceNo, // 会议号
            conferenceId: conference.id, // 会议id
            userName: encodeURIComponent(resData.info.username), // 展示名
          }
          if(paramsArr.domain){
            _queryData.domain=paramsArr.domain
          }
          this.$router.replace({
            // this.$router.push({
            name: "meeting",
            params: {
              userID: userid
            },
            query: _queryData
          });
        } catch (error) {
          this.joinErrorHandle(error)
        }

      },
    }
  }
</script>
<style scoped lang="scss">
  @mixin axios_style($height, $size, $weight, $color, $margin) {
    display: inline-block;
    height: $height;
    font-size: $size;
    font-weight: $weight;
    font-family: PingFangSC-Medium, PingFang SC;
    color: $color;
    line-height: $height;
    text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    margin: $margin;
  }

  @mixin axios_item($weidth, $height, $size, $weight, $color, $margin) {
    width: $weidth;
    height: $height;
    font-size: $size;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: $weight;
    color: $color;
    line-height: 22px;
    text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    margin: $margin;
  }
  .dialog-marginTop{
    display: block;
    margin-top: 60px;
  }
  .bg-default{
    background-image: url(../../assets/images/back.png);
  }
  .bg-omnitest{
    background-image: url(../../assets/images/backOmnitest.png);
    background-position: center;
    background-size: cover;
  }
  .join-container {
    background-color: #ffffff;
    background-size: cover;

    #videoconference_page {
      // min-height: 100%;

      .premeeting-screen {
        align-items: stretch;
        //background: radial-gradient(50% 50% at 50% 50%, #2a3a4b 20.83%, #1e2a36 100%);
        display: flex;
        flex-direction: column;
        font-size: 1.3em;
        z-index: 2;


        #preview {
          height: 100%;
          position: absolute;
          width: 100%;

          video {
            height: 100%;
            object-fit: cover;
            position: absolute;
            width: 100%;
            display: inline-block;
            vertical-align: baseline;
          }

          .flipVideoX {
            transform: scale(-1, 1);
            -moz-transform: scale(-1, 1);
            -webkit-transform: scale(-1, 1);
            -o-transform: scale(-1, 1);
          }
        }

        .content {
          z-index: 4;
          margin: auto;

          .box {
            width: 412px;
            height: 474px;
            box-sizing: border-box;
            background-color: #fff;
            align-items: center;
            display: flex;
            flex-direction: column;
            border-radius: 8px;
          }

          .box.gray {
            background: rgba(51, 51, 51, .4);
            border-radius: 8px;
          }

          .prejoin-input-area {
            display: flex;
            flex-direction: column;
          }

          .title {
            width: 89%;
            height: 90px;
            margin-top: 24px;
            font-weight: 500;
            font-size: 20px;
            color: #333333;
            position: relative;
            word-break: break-all;

            p {
              width: 100%;
              line-height: 30px;
              position: absolute;
              top: 50%;
              left: 50%;
              text-align: center;
              transform: translate(-50%, -50%);
              word-break: break-word;
            }
          }

          .title.active {
            color: #ffffff;
            word-break: break-all;
          }

          .time_style {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 22px;
            margin-top: 10px;
          }

          .line_style {
            width: 364px;
            height: 1px;
            background: #EEEEEE;
            margin-top: 16px;
          }

          ::v-deep .input-box {
            font-size: 14px;
            width: 16px;
            height: 17px;
          }

          ::v-deep .input__word {
            font-size: 14px;
          }

          .prejoin-button {
            margin-top: 101px;

            button {
              width: 332px;
              height: 40px;
              font-size: 16px;
              font-weight: normal;
            }
          }
        }
      }

      .premeeting-screen {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }

  .errorTip2 {
    margin-top: 10px;
    margin-left: 40px;
  }


  .axiosItemTitle {
    @include axios_item(120px, 22px, 13px, 600, #999999, 10px 0 0 24px);
    float: left;
  }

  .axiosItemValue {
    @include axios_item(200px, 22px, 16px, 500, #666666, 10px 0 0 24px);
    float: left;
  }

  .axiosTitle {
    @include axios_style(28px, 20px, 600, #333333, 4px 0 20px 24px);
  }

  .axiosItemValue2 {
    @include axios_style(22px, 16px, 500, #666666, 10px 0 20px 24px);
    float: left;
  }

  .mutRowsTitle {
    width: 90%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    height: auto;
  }

  ::v-deep .labelColor .el-checkbox__label {
    color: #ffffff;
  }

  ::v-deep .el-checkbox,
  ::v-deep .no_video .el-checkbox__input.is-checked+.el-checkbox__label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }

  ::v-deep .input {
    margin-right: 0;
  }
</style>
